<template>
  <div class="content">
    <div class="pageContent" v-loading="loading" >
      <div
        v-if="
          USER_INFO.company_bind_status == 2 && companyFileAndInvoiceNum == 4
        "
      >
        <div class="step">
          <el-steps :active="active" simple finish-status="success">
            <el-step title="确定订舱要素"></el-step>
            <el-step title="上传订舱文件"></el-step>
            <el-step title="补充订舱信息"></el-step>
            <el-step title="完成订舱"></el-step>
          </el-steps>
        </div>
        <div class="tepOne" v-show="active == 0">
          <div class="tepOneInput">
            <first ref="stepFirst" @firstData="firstData"></first>
            <el-button
              type="success"
              class="nextSstep"
              @click="toSecond"
              :loading="loading"
              >下一步</el-button
            >
          </div>
          <div class="tepOneTips">
            <p class="title">
              <i style="color: #e6a23c" class="el-icon-warning"></i>特别说明：
            </p>
            <p class="tipsTxt">
              1.
              委托书上的危险品数据必须和最终提单要显示的危险品数据一致，包括件数、毛重、净重、体积、品名（不接受提单确认时和委托书上不一致的品名），HS CODE；<br />
              2.
              MSC、ZIM、YML三个船公司，若箱子里普货、一般化工品和危险品混装，那么箱子里普货、一般化工品和危险品一样，委托书里的数据（如第2条的具体数据）也必须和提单一致，
              而且一般化工品必须提供上海化工研究院的非危鉴定；<br />
              3. MSC、ZIM收发通的委托书必须和提单一致；<br />
              4. MSC、ZIM和CMA必须提供收货人24小时紧急联系人姓名和电话；
            </p>
          </div>
        </div>
        <div class="tepTwo" v-show="active == 1">
          <second
            ref="stepSecond"
            :config="configData ? configData.datum_elements : ''"
            @SecondtData="SecondtData"
          ></second>
          <div class="stepBtns">
            <el-button class="lastStep" @click="active = 0" plain
              >返回上一步</el-button
            >
            <el-button class="nextSstep2" type="success" @click="toThird"
              >下一步</el-button
            >
          </div>
        </div>
        <div class="tepThird" v-show="active == 2">
          <third
            :hasPower="
              secondForm.file1 && secondForm.file1.length ? true : false
            "
            ref="third"
            :config="configData ? configData.data_elements : ''"
            @thirdData="thirdData"
          ></third>
          <div class="stepBtns">
            <el-button class="lastStep" @click="active = 1" plain
              >返回上一步</el-button
            >
            <el-button class="nextSstep2" type="success" @click="toLast"
              >下一步</el-button
            >
          </div>
        </div>
        <div class="tepThird" v-show="active == 3">
          <last
            :firstForm="firstForm"
            :secondForm="secondForm"
            :thirdForm="thirdForm"
            :config="configData ? configData.data_elements : ''"
              :hasPower="
              secondForm.file1 && secondForm.file1.length ? true : false
            "
            :configFile="configData ? configData.datum_elements : ''"
          ></last>
          <div class="stepBtns">
            <el-button class="lastStep" @click="active = 2" plain
              >返回上一步</el-button
            >
            <el-button
              class="nextSstep2"
              v-loading="loading"
              type="success"
              @click="submitAll"
              >提交订舱委托</el-button
            >
          </div>
        </div>
      </div>
        <el-dialog
        title="补充公司信息"
        :visible.sync="addCompanyInfoDialog"
        append-to-body
    >
      <addCompanyInfo v-if="addCompanyInfoDialog" :companyFileAndInvoiceNum="companyFileAndInvoiceNum" @upOK="upOK"
                      @handleClose="handleClose"></addCompanyInfo>
    </el-dialog>
      <yetJoin
       
        v-show="
          USER_INFO.company_bind_status == 0 ||
          USER_INFO.company_bind_status == 3
        "
        @bindCompany="bindCompany"
      ></yetJoin>
      <div v-if="USER_INFO.company_bind_status == 1" class="bind_status">
        认证申请需由公司主账号审核，如有疑问可联系客服人员处理！
      </div>
    </div>
  </div>
</template>

<script>
import first from "./first";
import second from "./second";
import third from "./third";
import last from "./last";
import addCompanyInfo from "~ymp/components/bookingSpace/addCompanyInfo"

import yetJoin from "~bac/components/company/basic/yetJoin";
export default {
  components: { first, second, third, last, yetJoin ,addCompanyInfo},
  data() {
    return {
      first_mounted:true,
      fastData:{},
      addCompanyInfoDialog:false,
      active: 0,
      firstForm: {},
      secondForm: {},
      thirdForm: {},
      configData: null,
      loading: false,
      companyFileAndInvoiceNum: 3, // 1是没证书，2 是没发票，3是都没有，4是都有
    };
  },
  mounted() {
    if (!this.IS_LOGIN) {
      return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
        waitTodo: () => {
          this.isLogic();
        },
      });
    } else {
      this.isLogic();
    }
    if(!this.$route.query.orderId){
      this.$store.commit("saveFullBookingDetails", '');
    }
    if(this.$route.query.from=="console"){
      this.fastBook()
    }
  },
  watch:{
    active:function(e){
      console.log('activeactive',e);
    },
  },
  methods: {
    async fastBook(){
      let parmas = {
        id: this.$route.query.orderId,
      };
      this.loading = true;
      let res = await this.$store.dispatch(
        "baseConsole/getEntrustDetails",
        parmas
      );
      if (res.success) {
        let data=res.data
        this.fastData=res.data
        console.log('res.data',res.data);

         let res_num = await this.$store.dispatch("API_fcl_bookingSpace/checkNo", {
          quotation_no:data.quotation_no,
          from_port:data.from_port,
          dest_port:data.dest_port,
          shipping_company:data.preplan_shipping_company,
        });
        let firstValues={
            pol: data.from_port_name_en,
            pol_id: data.from_port,
            pod: data.dest_port_name_en,
            pod_id: data.dest_port,
            time: new Date(data.preplan_shipment * 1000),
            company: data.preplan_shipping_company,
            type: data.cargo_type,
            no:res_num.success?data.quotation_no:"",
            id: null,
        }
        this.$refs.stepFirst.firstSetValues(firstValues); //给第一步赋值
        setTimeout( async ()=>{
          await this.toSecond() 
        },300)
        
      }
    },
    //绑定成功后，再去查看有没有开票信息和证书
    bindCompany() {
      this.isLogic();
    },
    isLogic() {
      //判断逻辑,只有绑定公司了，再去判断是否有公司证书和开票信息
      if (this.USER_INFO.company_bind_status == 2) {
        this.getCompanyFileAndInvoice();
      }
    },
    // 判断有没有开票信息和有没有证书
    async getCompanyFileAndInvoice() {
      if (this.USER_INFO.company_bind_status == 0) return;
      let params = {
        company_id: this.USER_INFO.company_id,
      };
      let res = await this.$store.dispatch(
        "API_bookingSpace/getCompanyFileAndInvoice",
        params
      );
      if (res.success) {
        let data = res.data;
        if (
          JSON.stringify(data.invoice_data) == "{}" &&
          JSON.stringify(data.qualification_file) == "{}"
        ) {
          this.$message.warning("订舱前请先上传公司证件和开票信息");
          this.companyFileAndInvoiceNum = 3;
          this.addCompanyInfoDialog = true;
          return;
        }
        if (JSON.stringify(data.qualification_file) == "{}") {
          this.$message.warning("订舱前请先上传公司证件");
          this.companyFileAndInvoiceNum = 1;
          this.addCompanyInfoDialog = true;
        }
        if (JSON.stringify(data.invoice_data) == "{}") {
          this.$message.warning("订舱前请先上传开票信息");
          this.companyFileAndInvoiceNum = 2;
          this.addCompanyInfoDialog = true;
        }
        if (
          JSON.stringify(data.invoice_data) != "{}" &&
          JSON.stringify(data.qualification_file) != "{}"
        ) {
          this.companyFileAndInvoiceNum = 4;
          this.addCompanyInfoDialog = false;
        }
      }
    },
      upOK() {
      this.companyFileAndInvoiceNum = 4
      this.addCompanyInfoDialog = false
    },
    handleClose() {
      this.addCompanyInfoDialog = false
    },
    async submitAll() {
      this.loading = true;
      let parmas = {
        user_id: this.USER_INFO.id,
        company_id: this.USER_INFO.company_id,
        entrust_kind: 1,
        cas_no: this.secondForm.casNo,
        // is_big:this,
        quotation_no: this.firstForm.no,
        files_info: JSON.stringify(
          this.secondForm.file1
            .concat(this.secondForm.file2)
            .concat(this.secondForm.file3)
            .concat(this.secondForm.file4)
            .concat(this.secondForm.file6)
            .concat(this.secondForm.file7)
        ),
        user_remarks:this.secondForm.user_remarks,
        from_port: this.firstForm.pol_id,
        dest_port: this.firstForm.pod_id,
        preplan_shipping_company: this.firstForm.company,
        preplan_shipment: this.$moment(this.firstForm.time).unix(),
        un_no: this.thirdForm.unno,
        cargo_name: this.thirdForm.name,
        mark_numbers: this.thirdForm.mark,
        shipping_terms: this.thirdForm.transport,
        fee_terms: this.thirdForm.freight,
        address_info: this.thirdForm.address
          ? JSON.stringify(this.thirdForm.address.address_info)
          : "",
        entrust_type: this.secondForm.file1.length ? 1 : 2,
        created_from: 1,
        net_weight: this.thirdForm.weight,
        packing_type: this.thirdForm.kinds,
        hs_code: this.thirdForm.hs,
        container: JSON.stringify({
          list: this.thirdForm.gpList,
          totalNo: this.thirdForm.totalNo,
          totalWeight: this.thirdForm.totalWeight,
          totalVolum: this.thirdForm.totalVolum,
        }),
        // cargo_type: this.firstForm.type.join(","), 多选变单选
        cargo_type: this.firstForm.type,
        emergency_contact: this.thirdForm.user,
        emergency_contact_tel: this.thirdForm.phone,
        quotation_id: this.firstForm.quotation_id,
        freight_id: this.firstForm.id,
        // is_whole_case:1,
        // whole_box:,
      };
      if (this.$route.query.subType == 2) { //subType =1 重新委托。  2 复制委托
        parmas.id = this.$route.query.orderId;
        parmas.status = 0;
      }
      let res = await this.$store.dispatch(
        "API_fcl_bookingSpace/submitEntrust",
        parmas
      );
      this.loading = false;
      if (res.success) {
        this.$router.push({
          path: "/bookingSpaceTitle",
          query: {
            id: res.data,
            isFcl: 1,
            status: 0,
          },
        });
      } else {
        this.$message.error("订舱信息有误");
      }
    },
    //绑定成功后，再去查看有没有开票信息和证书
    bindCompany() {
      this.isLogic();
    },
    toLast() {
      this.$refs.third.saveThird();
    },
    async toSecond() {
      console.log('0000cao ');
      await this.$refs.stepFirst.getFirstStepData();
    },
    toThird() {
      this.$refs.stepSecond.getSecondStepData();
    },

    thirdData(e) {
      console.log(e);
      if (e.address) {
        e.address.address_info = JSON.parse(e.address.address_info);
      }
      this.thirdForm = e;
      this.active = 3;
    },
    SecondtData(e) {
      console.log(e);
      this.secondForm = e;
      this.active = 2;
    },
    async firstData(e) {
      this.loading = true;
      this.firstForm = e;
      console.log(e);
      let parmas = {
        spell_type: 1,
        cargo_type: e.type,
        shipping_company: e.company,
        nopage: 1,
      };
      let res = await this.$store.dispatch(
        "API_fcl_bookingSpace/bookingElementsConfig",
        parmas
      );
      if (res.success) {
        this.loading = false;
        this.configData = res.data[0];
        this.active = 1;
        if(this.first_mounted&&this.$route.query.from=='console'){   // 控制台  快速订舱
            this.first_mounted=false

            //调用第一步中的下一部事件 请求第二页所需的参数
            this.$refs.stepSecond.secondSetValues(this.fastData)//给第二步赋值
            setTimeout(()=>{
              this.toThird()
            },300)
            setTimeout(()=>{
              this.$refs.third.thirdSetValues(this.fastData)//给第三步赋值
            },300)
            

            setTimeout(()=>{
              this.toLast()
            },300)
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .yetJoinComponentes{
  padding: 50px 0;
  margin-top: 0;
}
.bind_status {
  text-align: center;
  line-height: 200px;
  font-size: 18px;
}
.tepThird {
  background: #fff;
  padding: 30px;
}
.stepBtns {
  display: flex;
  justify-content: space-around;
  padding: 0 280px;
  margin-top: 50px;
}
.lastStep {
  width: 216px;
}
.nextSstep2 {
  width: 216px;
}
.tepTwo {
  background: #fff;
  padding: 40px 131px;
}
.tepOneTips {
  padding: 40px 20px;
  .title {
    font-size: 16px;
    font-weight: 600;
    i {
      padding-right: 10px;
      font-size: 20px;
    }
  }
  .tipsTxt {
    line-height: 30px;
    font-size: 14px;
    margin-top: 20px;
  }
}
.tepOneInput {
  background: #fff;
  padding: 40px 131px;
}
.nextSstep {
  width: 216px;
  margin: 0 auto;
  display: block;
}
.content {
  background: #f2f2f2;
  padding-bottom: 50px;
  min-height: 770px;

  .pageContent {
    width: 1300px;
    margin: 0 auto;
    .step {
      padding: 30px 0;
      .el-steps {
        background: #f2f2f2;
      }
    }
  }
}
</style>