<template>
  <div class="contentFirst">
    <el-form
      ref="form"
      :rules="rules"
      label-width="100px"
      :model="form"
      class="demo-form-inline"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="起运港" prop="pol">
            <portSelect
              :disabled="(isConsole || $route.query.id?true:false)&&$route.query!='console'"
              :width="'280px'"
              v-model="form.pol"
              placeTitle="请输入起运港"
              clearable
              @input="pol_input"
            /> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item label="目的港" prop="pod">
            <portSelect
              :disabled="(isConsole || $route.query.id?true:false)&&$route.query!='console'"
              :width="'280px'"
              v-model="form.pod"
              placeTitle="请输入目的港"
              clearable
              @input="pod_input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="预配船期" prop="time" style="margin-bottom: 0">
            <el-date-picker
              :disabled="IsReject"
              style="width: 280px"
              size="small"
              v-model="form.time"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptions"
            >
              >
            </el-date-picker>
            <p class="inputTips">
              预配船期不代表最终订舱的船期，根据船公司运力情况有所调整
            </p>
          </el-form-item></el-col
        >
        <el-col :span="12">
          <el-form-item
            label="预配船公司"
            prop="company"
            style="margin-bottom: 0"
          >
            <shipCompanySearch
              :disabled="(isConsole || $route.query.id?true:false)&&$route.query!='console'"
              :width="'280px'"
              size="small"
              class="from_input"
              v-model="form.company"
              :modelVlaue="'code'"
            ></shipCompanySearch>
            <p class="inputTips">
              如有确定意向的船公司，请填写，以免遗漏必要资料
            </p>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="货物类型" prop="type" style="margin-bottom: 0"> 
            <!-- <el-checkbox-group
              :disabled="isConsole"
              v-model="form.type"
              @change="test"
            >
              <el-checkbox
                v-for="item in checkList"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group> -->
            <el-radio-group v-model="form.type">
              <el-radio v-for="item in checkList"
                :key="item.id"
                :label="item.id" >{{ item.label }}</el-radio>
            </el-radio-group>



            <!-- <p class="inputTips" style="line-height:12px;margin-bottom:15px">
              请按照委托货物的实际类别勾选，可勾选多个
            </p> -->
          </el-form-item></el-col
        >
      </el-row>
      <el-row v-if="!isConsole">
        <el-col :span="24">
          <el-form-item label="报价编号" prop="no">
            <el-input
              v-model="form.no"
              size="small"
              style="width: 280px"
              placeholder="请输入报价编号"
            ></el-input>
            <div style="color: #999"
              >（如您已经向销售咨询过运价，请填入销售提供的报价编号，请注意报价中的港口信息，船公司等需与订舱要素相同）</div
            >
          </el-form-item></el-col
        >
      </el-row>
      <el-form-item>
        <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import shipCompanySearch from "~ymp/components/common/shipCompanySearch";

import portSelect from "~ymp/components/common/portSelect";
export default {
  components: { portSelect, shipCompanySearch },
  data() {
    var check = async (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        let data = await this.$store.dispatch("API_fcl_bookingSpace/checkNo", {
          quotation_no: this.form.no,
          from_port: this.form.pol_id,
          dest_port: this.form.pod_id,
          shipping_company: this.form.company,
        });
        if (data.success) {
          callback();
        } else {
          callback(new Error("报价编号有误，请重新输入"));
        }
      }
    };
    return {
      pickerOptions: {
        disabledDate: (time) => {
          if (this.shipping_date) {
            let arr = this.shipping_date.split(",");
            return (
              (this.$moment(time.getTime()).day() != arr[0] &&
                this.$moment(time.getTime()).day() != arr[1] &&
                this.$moment(time.getTime()).day() != arr[2]) ||
              time.getTime() < Date.now() - 24 * 3600 * 1000
            );
          } else {
            return time.getTime() < Date.now() - 24 * 3600 * 1000; //如果不包括今天。就是return time.getTime() > Date.now() - 24*3600*1000;
          }
        },
      },
      checkList: [
        // {
        //   label: "普通货物",
        //   id: "1",
        // },
        // {
        //   label: "一般化工品",
        //   id: "2",
        // },
        // {
        //   label: "危险品",
        //   id: "3",
        // },
        {
          label: "一般化工品（包括普通货物）",
          id: "2",
        },
        {
          label: "危险品",
          id: "3",
        },
        {
          label: "混装货物（包含危险品和普货）",
          id: "4",
        },
      ],
      form: {
        pol: null,
        pol_id: null,
        pod: null,
        pod_id: null,
        time: null,
        company: null,
        type: [],
        no: null,
        id: null,
        quotation_id: null,
      },
      shipping_date: null,
      rules: {
        pol: [{ required: true, message: " ", trigger: "change" }],
        pod: [{ required: true, message: " ", trigger: "change" }],
        time: [{ required: true, message: " ", trigger: "change" }],
        type: [{ required: true, message: " ", trigger: "change" }],
        no: [{ validator: check, trigger: "blur" }],
      },
      isConsole: false,
      IsReject: false,
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getDetail();
    }
    if (this.$store.state.fullBookingDetails && !this.$route.query.subType) {
      this.isConsole = true;
    }
    if (
      this.$store.state.fullBookingDetails &&
      this.$store.state.fullBookingDetails.status == 4
    ) {
    }
    if (
      this.$store.state.fullBookingDetails &&
      this.$store.state.fullBookingDetails.status != 0 &&
      !this.$route.query.subType
    ) {
      this.IsReject = true;
    }
    if (this.$store.state.fullBookingDetails) {
      let data = this.$store.state.fullBookingDetails;
      if (data.shipping_date) {
        this.shipping_date = data.shipping_date;
      }
      console.log('aaaaaaaaa',data);
      this.form = {
        pol: data.from_port_name_en,
        pol_id: data.from_port,
        pod: data.dest_port_name_en,
        pod_id: data.dest_port,
        time: new Date(data.preplan_shipment * 1000),
        company: data.preplan_shipping_company,
        type: data.cargo_type,
        no:data.quotation_no,
        id: null,
      };
    }
  },
  methods: {
    firstSetValues(obj){ // 控制台快速订舱用
        this.IsReject = false
        this.isConsole=false
        this.form=obj
    },
    test() {
      console.log(this.form.type);
    },

    async getDetail() {
      let result = await Promise.all([
        this.$store.dispatch("baseConsole/getMyInquireInfo", {
          inquiry_id: this.$route.query.id,
        }),
      ]);
      console.log(result[0]);
      if (result[0].success) {
        let data = result[0].data;
        this.form.pol = data.from_port_name_en;
        this.form.pol_id = data.from_port;
        this.form.pod = data.dest_port_name_en;
        this.form.pod_id = data.dest_port;
        this.form.company = data.shipping_company_code;
        this.shipping_date = data.shipping_date;
        this.form.id = data.freight_id;
        this.form.quotation_id = data.quotation_id;
        if (data.is_quote) {
          this.form.no = data.quotation_no;
        }
      }
    },

    getFirstStepData() {
      this.$refs["form"].validate((valid) => {
        if(this.form.type==""){
          this.$message.warning("请选择货物类型")
          return
        }
        if (valid) {
          this.$emit("noFirst", false);
          this.$emit("firstData", this.form);
        } else {
          this.$emit("noFirst", true);
          return false;
        }
      });
    },
    pol_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        console.log(this.form.pol)
        this.form.pol_id = val;
      }
    },
    pod_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.form.pod_id = val;
      }
    },
  },
};
</script>

<style scoped lang="less">
.contentFirst {
  //   background: #fff;
  //   padding: 40px 131px;
}
.inputTips{
  font-size: 12px;
  color: #999;
}
</style>