<template>
  <div class="thirdBox">
    <p class="title">委托信息</p>
    <el-form
      label-position="left"
      label-width="160px"
      :model="form"
      class="formBox"
      ref="form"
      :show-message="false"
    >
      <el-form-item
        label="包装类型"
        v-if="!hasPower"
        required
        prop="kinds"
        class="formItem"
      >
        <el-select
          :disabled="IsReject"
          v-model="form.kinds"
          size="small"
          style="width: 200px"
          placeholder="请选择"
          @change="changeUnit"
        >
          <el-option
            v-for="item in numberUnitList"
            :key="item.id"
            :label="item.name_en"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
        <el-form-item
        label="箱型箱量"
        v-if="needShow"
        prop="kinds"
        class="formItem"
      >
         <span v-if="gp20No"> 20GPx{{ gp20No }} </span>
            <span v-if="gp40No"> 40GPx{{ gp40No }} </span>
            <span v-if="hq40No"> 40HQx{{ hq40No }} </span>
      </el-form-item>
      <el-form-item
        label="净重"
        v-if="config.indexOf('1') > -1"
        required
        prop="weight"
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          type="number"
          size="small"
          placeholder="请输入净重"
          v-model="form.weight"
          style="width: 350px"
        >
          <template slot="append">KGS</template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="品名"
        v-if="!hasPower"
        required
        prop="name"
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          type="textarea"
          style="width: 350px"
          rows="5"
          v-model="form.name"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="标志及号码"
        v-if="!hasPower"
        required
        prop="mark"
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          type="textarea"
          rows="5"
          style="width: 350px"
          v-model="form.mark"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="运输条款"
        v-if="!hasPower"
        required
        prop="transport"
        class="formItem"
      >
        <el-select
          :disabled="IsReject"
          v-model="form.transport"
          size="small"
          style="width: 350px"
          placeholder="请选择"
          @change="changeTransport"
        >
          <el-option
            v-for="item in shippingTermsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="运费条款"
        v-if="!hasPower"
        required
        prop="freight"
        class="formItem"
      >
        <el-select
          :disabled="IsReject"
          v-model="form.freight"
          size="small"
          style="width: 350px"
          placeholder="请选择"
          @change="changeFreight"
        >
          <el-option
            v-for="item in feeTermsList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="24小时紧急联系人"
        required
        prop="user"
        v-if="config.indexOf('4') > -1"
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          size="small"
          placeholder=""
          v-model="form.user"
          style="width: 350px"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="24小时紧急联系电话"
        required
        v-if="config.indexOf('5') > -1"
        prop="phone"
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          size="small"
          placeholder=""
          v-model="form.phone"
          style="width: 350px"
        >
        </el-input>
      </el-form-item>
      <el-form-item
        label="UNNO"
        v-if="config.indexOf('2') > -1"
        prop="unno"
        required
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          size="small"
          placeholder=""
          v-model="form.unno"
          style="width: 350px"
        >
        </el-input>
        <p style="font-size:12px;color:#999">危险品货物必需提供UNNO，如有多个UNNO，请用英文,隔开</p>
      </el-form-item>
      <el-form-item
        label="HS CODE"
        v-if="config.indexOf('3') > -1"
        prop="hs"
        required
        class="formItem"
      >
        <el-input
          :disabled="IsReject"
          size="small"
          placeholder=""
          v-model="form.hs"
          style="width: 350px"
        >
        </el-input>
        <p style="font-size:12px;color:#999">如有多个HS CODE，请用英文,隔开</p>
      </el-form-item>

      <uploadAdress
        v-if="!hasPower"
        :histroyInfo="histroyInfo"
        style="width: 100%"
        ref="uploadAdress"
      ></uploadAdress>
      <p class="title" style="width: 100%; margin-top: 50px">装箱信息</p>
      <el-form-item label="" label-width="0" prop="gpList" style="width: 100%">
        <div class="total">
          <span>总件数：{{ totalNo }}</span>
          <span>总毛重：{{ totalWeight }} KGS</span>
          <span>总体积：{{ totalVolum }} CBM</span>
        </div>
        <el-table :data="form.gpList" style="width: 100%">
          <el-table-column prop="type" label="箱型箱量"> </el-table-column>
          <el-table-column prop="no" label="件数"> </el-table-column>
          <el-table-column prop="weight" label="毛重（KGS）"> </el-table-column>
          <el-table-column prop="volume" label="体积（CBM）"> </el-table-column>
          <el-table-column prop="nameEn" label="英文品名" width="300px">
            <template slot-scope="scope">
              <pre style="width: 250px">{{ scope.row.nameEn }}</pre>
            </template>
          </el-table-column>
          <el-table-column prop="base" label="备注"> </el-table-column>
          <el-table-column prop="weight" label="操作"  v-if="!IsReject">
            <template slot-scope="scope">
              <el-button
               
                type="text"
                @click="deleteItem(scope.$index)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 30px" v-if="!IsReject">
          <el-button
            icon="el-icon-plus"
            size="small"
            type="primary"
            @click="dialogVisible = true"
            >添加装箱信息</el-button
          >
          <span style="padding-left: 30px" v-if="hasPower"
            >如有多个货箱，可为每个货箱设置装箱信息，以上信息将根据分装箱信息计算总量</span
          >
            <span style="padding-left: 30px" v-else
            >设置装箱信息后，以上信息将根据每个货箱信息计算总量，至少需要添加一个装箱信息</span
          >
        </div>
      </el-form-item>
    </el-form>
    <el-dialog title="添加装箱信息" :visible.sync="dialogVisible" width="900px">
      <el-form
        label-position="left"
        label-width="90px"
        :model="gpForm"
        ref="gpForm"
        class="formBox"
        style="margin: 0"
        :show-message="false"
      >
        <el-form-item label="箱型箱量" required prop="type" class="formItem">
          <el-select
            v-model="gpForm.type"
            size="small"
            style="width: 250px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="件数" required prop="no" class="formItem">
          <el-input
            v-model="gpForm.no"
            style="width: 250px"
            size="small"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="毛重" required prop="weight" class="formItem">
          <el-input
            v-model="gpForm.weight"
            style="width: 250px"
            size="small"
            placeholder=""
            ><template slot="append">KGS</template></el-input
          >
        </el-form-item>
        <el-form-item label="体积" required prop="volume" class="formItem">
          <el-input
            v-model="gpForm.volume"
            style="width: 250px"
            size="small"
            placeholder=""
            ><template slot="append">CMB</template></el-input
          >
        </el-form-item>
        <el-form-item label="英文品名" prop="nameEn" class="formItem">
          <el-input
            v-model="gpForm.nameEn"
            style="width: 250px"
            type="textarea"
            size="small"
            rows="5"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="base" class="formItem">
          <el-input
            v-model="gpForm.base"
            type="textarea"
            style="width: 250px"
            size="small"
            rows="5"
            placeholder=""
          ></el-input>
        </el-form-item>
        <div class="gpbtns">
          <el-button
            size="small"
            style="width: 100px"
            @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button
            size="small"
            style="width: 100px"
            type="primary"
            @click="submitForm('gpForm')"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import uploadAdress from "~ymp/components/bookingSpace/uploadEntrustSon/uploadAdress";

export default {
  props: {
    config: {
      type: String,
      default: "1,2,3,4",
    },
    hasPower: {
      type: Boolean,
      default: false,
    },
    needShow:{
      type: Boolean,
      default: false,
    }
  },
  watch: {
    hasPower(newName, oldName) {},
  },
  components: { uploadAdress },
  data() {
    return {
      typeOptions: [
        {
          label: "20GP",
          id: 1,
        },
        {
          label: "40GP",
          id: 2,
        },
        {
          label: "40HQ",
          id: 3,
        },
      
      ],
      dialogVisible: false,
      gpForm: {
        type: "",
        no: "",
        weight: "",
        volume: "",
        nameEn: "",
        base: "",
      },

      form: {
        address: null,
        kinds: null,
        kindsName: null,
        weight: null,
        name: null,
        mark: null,
        transport: null,
        transportName: null,
        freight: null,
        freightName: null,
        user: null,
        phone: null,
        unno: null,
        hs: null,
        gpList: [],
        totalNo: null,
        totalWeight: null,
        totalVolum: null,
      },
      numberUnitList: [],
      shippingTermsList: [],
      feeTermsList: [],
      histroyInfo: {},
      IsReject: false,
    };
  },
  computed: {
     gp20No: function () {
      // `this` 指向 vm 实例
      let no = 0
      if(this.form.gpList.length){
        this.form.gpList.forEach(res=>{
          if(res.type =='20GP'){
            no=no+Number(res.no) 
          }
        })
      }
      return no;
    },
     gp40No: function () {
      // `this` 指向 vm 实例
      let no = 0
      if(this.form.gpList.length){
        this.form.gpList.forEach(res=>{
          if(res.type =='40GP'){
            no=no+Number(res.no) 
          }
        })
      }
      return no;
    },
     hq40No: function () {
      // `this` 指向 vm 实例
      let no = 0
      if(this.form.gpList.length){
        this.form.gpList.forEach(res=>{
          if(res.type =='40HQ'){
            no=no+Number(res.no) 
          }
        })
      }
      return no;
    },
    totalNo() {
      let no = 0;
      this.form.gpList.forEach((element) => {
        no = no + Number(element.no);
      });
      this.form.totalNo = no;

      return no;
    },
    totalWeight: function () {
      let no = 0;
      this.form.gpList.forEach((element) => {
        no = no + Number(element.weight);
      });
      this.form.totalWeight = no;

      return no;
    },
    totalVolum: function () {
      let no = 0;
      this.form.gpList.forEach((element) => {
        no = no + Number(element.volume);
      });
      this.form.totalVolum = no;
      return no;
    },
  },
  mounted() {
    
    this.initList();
    if (
      this.$store.state.fullBookingDetails &&
      this.$store.state.fullBookingDetails.status != 0 &&
      !this.$route.query.subType
    ) {
      this.IsReject = true;
    }
    if (this.$store.state.fullBookingDetails) {
      let data = this.$store.state.fullBookingDetails;
      this.form = {
        address: null,
        kinds: data.packing_type,
        kindsName: null,
        weight: data.net_weight,
        name: data.cargo_name,
        mark: data.mark_numbers,
        transport: data.shipping_terms,
        transportName: null,
        freight: data.fee_terms,
        freightName: null,
        user: data.emergency_contact,
        phone: data.emergency_contact_tel,
        unno: data.un_no,
        hs: data.hs_code,
        gpList: JSON.parse(data.container).list,
        totalNo: null,
        totalWeight: null,
        totalVolum: null,
      };
    }
    //  let uploadAdress = await this.$refs.uploadAdress.saveFn()
  },
  methods: {
    thirdSetValues(data){
        this.form = {
          address: null,
          kinds: data.packing_type,
          kindsName: null,
          weight: data.net_weight,
          name: data.cargo_name,
          mark: data.mark_numbers,
          transport: data.shipping_terms,
          transportName: null,
          freight: data.fee_terms,
          freightName: null,
          user: data.emergency_contact,
          phone: data.emergency_contact_tel,
          unno: data.un_no,
          hs: data.hs_code,
          gpList: JSON.parse(data.container).list,
          totalNo: null,
          totalWeight: null,
          totalVolum: null,
      };
      this.histroyInfo=data
    },
    deleteItem(index) {
      console.log(index);
      this.form.gpList.splice(index, 1);
    },
    changeFreight(e) {
      let data = this.feeTermsList.find((res) => {
        return res.id == e;
      });
      this.freightName = data.name;
    },
    changeTransport(e) {
      let data = this.shippingTermsList.find((res) => {
        return res.id == e;
      });
      this.transportName = data.name;
    },
    saveName() {
      let data1 = this.feeTermsList.find((res) => {
        return res.id == this.form.freight;
      });
      this.form.freightName = data1.name;
      let data2 = this.shippingTermsList.find((res) => {
        return res.id == this.form.transport;
      });
      this.form.transportName = data2.name;
      let data3 = this.numberUnitList.find((res) => {
        return res.id == this.form.kinds;
      });
      this.form.kindsName = data3.name_en;
    },
    changeUnit(e) {
      let data = this.numberUnitList.find((res) => {
        return res.id == e;
      });

      this.kindsName = data.name_en;
    },
    saveThird() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (!this.hasPower) {
            let data = await this.$refs.uploadAdress.saveFn();
            if (data) {
              this.form.address = data;
              console.log(data);
              if (this.form.gpList.length || this.hasPower) {
                this.$emit("noThird", false);
                 console.log(22222222222)
                this.saveName();

                this.$emit("thirdData", this.form);
              } else {
                this.$message({
                  message: "至少需要添加一个装箱信息",
                  type: "warning",
                });
              }
            }
          } else {
            if (this.form.gpList.length || this.hasPower) {
               console.log(3333333333333333)
              this.$emit("noThird", false);
              this.$emit("thirdData", this.form);
            } else {
              this.$message({
                message: "至少需要添加一个装箱信息",
                type: "warning",
              });
            }
          }
        } else {
          console.log("error submit!!");
          console.log(11111111111111111111)
          this.$emit("noThird", true);
          return false;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //   this.gpForm.nameEn=this.gpForm.nameEn.replace(/\s/g, '&nbsp;');
          this.form.gpList.push(this.gpForm);
          this.gpForm = {
            type: "",
            no: "",
            weight: "",
            volume: "",
            nameEn: "",
            base: "",
          };
          this.dialogVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async initList() {
      this.loading = true;
      let res = await Promise.all([
        this.$store.dispatch("API_bookingSpace/getBookingpacKingType"),
        this.$store.dispatch("API_bookingSpace/getBookingShippingTerms"),
        this.$store.dispatch("API_bookingSpace/getBookingFeeTerms"),
      ]);
      if (res[0].success) {
        this.numberUnitList = res[0].data;
      }
      if (res[1].success) {
        this.shippingTermsList = res[1].data;
      }
      if (res[2].success) {
        this.feeTermsList = res[2].data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.gpbtns {
  display: flex;
  justify-content: space-around;
  padding: 0 300px;
  width: 100%;
}
.title {
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}
.formBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px 0;
  .formItem {
    width: 50%;
    padding: 0 40px;
  }
}
.total {
  margin: 20px 0;
  span {
    padding-right: 100px;
  }
}
</style>