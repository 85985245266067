<template>
  <div class="lastBox">
    <el-alert
      title="请检查信息是否有缺失，检查完成后可提交资料完成订舱"
      show-icon
      type="warning"
      :closable="false"
    >
    </el-alert>
    <div class="box1">
      <p class="title">委托信息</p>
      <div class="box1Content">
        <div class="box1Item">
          <div class="box1ItemFl">起运港</div>
          <div class="box1ItemFr">{{ firstForm.pol_id | portFormat }}</div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">目的港</div>
          <div class="box1ItemFr">{{ firstForm.pod_id | portFormat }}</div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">预配船期</div>
          <div class="box1ItemFr">
            {{ firstForm.time | secondFormatNormal("LL") }}
            <p style="color: #999; font-size: 12px">
              预配船期不代表最终订舱的船期，根据船公司运力情况有所调整
            </p>
          </div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">预配船公司</div>
          <div class="box1ItemFr">{{ firstForm.company | textFormat }}</div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">货物类型</div>
          <div class="box1ItemFr">
            <span v-for="(item, index) in firstForm.type" :key="index">
              <i style="padding-right: 10px" v-if="item == 1">普通货物</i>
              <i style="padding-right: 10px" v-if="item == 2">一般化工品</i>
              <i style="padding-right: 10px" v-if="item == 3">危险品</i>
              <i style="padding-right: 10px" v-if="item == 4">混装货物</i>
            </span>
          </div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">报价编号</div>
          <div class="box1ItemFr">{{ firstForm.no | textFormat }}</div>
        </div>
        <div
          class="box1Item"
          v-if="secondForm.file1 && !secondForm.file1.length"
        >
          <div class="box1ItemFl">包装类型</div>
          <div class="box1ItemFr">{{ thirdForm.kindsName | textFormat }}</div>
        </div>
        <div class="box1Item" v-if="config.indexOf('1') > -1">
          <div class="box1ItemFl">净重</div>
          <div class="box1ItemFr">{{ thirdForm.weight | textFormat }}</div>
        </div>
        <div
          class="box1Item"
          v-if="secondForm.file1 && !secondForm.file1.length"
        >
          <div class="box1ItemFl">品名</div>
          <div class="box1ItemFr">{{ thirdForm.name | textFormat }}</div>
        </div>
        <div
          class="box1Item"
          v-if="secondForm.file1 && !secondForm.file1.length"
        >
          <div class="box1ItemFl">标志和号码</div>
          <div class="box1ItemFr">{{ thirdForm.mark | textFormat }}</div>
        </div>
        <div
          class="box1Item"
          v-if="secondForm.file1 && !secondForm.file1.length"
        >
          <div class="box1ItemFl">运输条款</div>
          <div class="box1ItemFr">
            {{ thirdForm.transportName | textFormat }}
          </div>
        </div>
        <div
          class="box1Item"
          v-if="secondForm.file1 && !secondForm.file1.length"
        >
          <div class="box1ItemFl">运费条款</div>
          <div class="box1ItemFr">{{ thirdForm.freightName | textFormat }}</div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">CAS NO.</div>
          <div class="box1ItemFr">{{ secondForm.casNo | textFormat }}</div>
        </div>
        <div class="box1Item" v-if="config.indexOf('2') > -1">
          <div class="box1ItemFl">UNNO</div>
          <div class="box1ItemFr">{{ thirdForm.unno | textFormat }}</div>
        </div>
        <div class="box1Item" v-if="config.indexOf('4') > -1">
          <div class="box1ItemFl">24小时紧急联系人</div>
          <div class="box1ItemFr">{{ thirdForm.user | textFormat }}</div>
        </div>
        <div class="box1Item" v-if="config.indexOf('5') > -1">
          <div class="box1ItemFl">24小时紧急联系电话</div>
          <div class="box1ItemFr">{{ thirdForm.phone | textFormat }}</div>
        </div>
        <div class="box1Item" v-if="config.indexOf('3') > -1">
          <div class="box1ItemFl">HS CODE</div>
          <div class="box1ItemFr">{{ thirdForm.hs | textFormat }}</div>
        </div>
      </div>
    </div>
    <!-- "[{"address_type":"shipper","info":{"name":"212","address":"121","email":"212","telephone":"21"}},{"address_type":"consignee","info":{"name":"212","address":"121","email":"212","telephone":"21"}},{"address_type":"notify_party","info":{"name":"212","address":"121","email":"212","telephone":"21"}}]" -->
    <div class="addressBox" v-if="secondForm.file1 && !secondForm.file1.length">
      <div
        class="addressItem"
        v-for="(item, index) in thirdForm.address
          ? thirdForm.address.address_info
          : []"
        :key="index"
      >
        <div class="addressUser" v-if="item.address_type=='shipper'" >发货人 Shipper</div>
        <div class="addressUser" v-if="item.address_type=='consignee'" >收货人 Consignee</div>
        <div class="addressUser" v-if="item.address_type=='notify_party'" >通知人 Notify Party</div>
        <div class="addressCompany">
          {{ item.info.name }}
        </div>
        <div class="addressAddress">
          {{ item.info.address }}
        </div>
        <div class="addressTel">{{ item.info.telephone }}</div>
        <div class="addressEmail">{{ item.info.email }}</div>
      </div>
    </div>
    <div class="box2">
      <p class="title">装箱信息</p>
      <div class="total" style="margin-top: 30px">
        <span>总件数：{{ thirdForm.totalNo }}</span>
        <span>总毛重：{{ thirdForm.totalWeight }} KGS</span>
        <span>总体积：{{ thirdForm.totalVolum }} CBM</span>
      </div>
      <el-table :data="thirdForm.gpList" style="width: 100%">
        <el-table-column prop="type" label="箱型箱量"> </el-table-column>
        <el-table-column prop="no" label="件数"> </el-table-column>
        <el-table-column prop="weight" label="毛重（KGS）"> </el-table-column>
        <el-table-column prop="volume" label="体积（CBM）"> </el-table-column>
        <el-table-column prop="nameEn" label="英文品名" width="300px">
          <template slot-scope="scope">
            <pre style="width: 250px">{{ scope.row.nameEn }}</pre>
          </template>
        </el-table-column>
        <el-table-column prop="base" label="备注"> </el-table-column>
      </el-table>
    </div>
    <div class="box1">
      <p class="title">订舱文件</p>
      <div class="box1Content">
        <div class="box1Item">
          <div class="box1ItemFl">海运出口委托书</div>
          <div class="box1ItemFr">
            <p
              v-for="(item, index) in secondForm.file1"
              @click="downUrl(item.url)"
              :key="index"
              style="color: #409eff"
            >
              {{ item.name | textFormat }}
            </p>
            <span v-if="secondForm.file1 && !secondForm.file1.length">-</span>
          </div>
        </div>
        <div class="box1Item"   v-if="configFile.indexOf('2') > -1">
          <div class="box1ItemFl">英文MSDS</div>
          <div class="box1ItemFr">
            <p
              v-for="(item, index) in secondForm.file2"
              @click="downUrl(item.url)"
              :key="index"
              style="color: #409eff"
            >
              {{ item.name | textFormat }}
            </p>
            <span v-if="secondForm.file2 && !secondForm.file2.length">-</span>
          </div>
        </div>
        <div class="box1Item"  v-if="configFile.indexOf('1') > -1">
          <div class="box1ItemFl">危包证</div>
          <div class="box1ItemFr">
            <p
              v-for="(item, index) in secondForm.file3"
              @click="downUrl(item.url)"
              :key="index"
              style="color: #409eff"
            >
              {{ item.name | textFormat }}
            </p>
            <span v-if="secondForm.file3 && !secondForm.file3.length">-</span>
          </div>
        </div>
        <div class="box1Item"   v-if="configFile.indexOf('4') > -1">
          <div class="box1ItemFl">非危鉴定</div>
          <div class="box1ItemFr">
            <p
              v-for="(item, index) in secondForm.file7"
              @click="downUrl(item.url)"
              :key="index"
              style="color: #409eff"
            >
              {{ item.name | textFormat }}
            </p>
            <span v-if="secondForm.file7 && !secondForm.file7.length">-</span>
          </div>
        </div>
        <div class="box1Item"   v-if="configFile.indexOf('3') > -1">
          <div class="box1ItemFl">发货人电子章</div>
          <div class="box1ItemFr">
            <p
              v-for="(item, index) in secondForm.file6"
              @click="downUrl(item.url)"
              :key="index"
              style="color: #409eff"
            >
              {{ item.name | textFormat }}
            </p>
            <span v-if="secondForm.file6 && !secondForm.file6.length">-</span>
          </div>
        </div>
        <div class="box1Item">
          <div class="box1ItemFl">商检报告</div>
          <div class="box1ItemFr">
            <p
              v-for="(item, index) in secondForm.file4"
              @click="downUrl(item.url)"
              :key="index"
              style="color: #409eff"
            >
              {{ item.name | textFormat }}
            </p>
            <span v-if="secondForm.file4 && !secondForm.file4.length">-</span>
          </div>
        </div>
        <div class="box1Item" style="width:100%">
            <div class="box1ItemFl" style="width: 140px;">备注</div>
            <div class="box1ItemFr">
              <el-input v-model="secondForm.user_remarks" readonly  rows="4" type="textarea" placeholder=""></el-input>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: String,
      default: "1,2,3,4",
    },
    configFile:{
       type: String,
      default: "1,2,3,4",
    },
    hasPower: {
      type: Boolean,
      default: false,
    },
    firstForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
       secondForm: {
      type: Object,
      default:()=>{
        return {}
      }
    },
       thirdForm: {
      type: Object,
      default:()=>{
        return {}
      }
    },
  },
  // props: ["firstForm", "secondForm", "thirdForm", "config", "hasPower"],
  data() {
    return {
      gpList: {},
    };
  },
  watch: {},
  mounted() {
    console.log(this.firstForm, this.secondForm, this.thirdForm);
  },
  methods: {
    downUrl(e) {
      window.location.href = e;
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-alert__title {
  color: #e6a23c;
}
.total {
  margin: 20px 0;
  span {
    padding-right: 100px;
  }
}
.title {
  font-size: 16px;
  font-weight: 600;
  padding: 30px 20px;
  border-bottom: 1px solid #dcdfe6;
}
.box1Content {
  width: 100%;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .box1Item {
    margin: 15px 0;
    width: 50%;
    display: flex;
    .box1ItemFl {
      width: 25%;
      text-align: left;
    }
    .box1ItemFr {
      width: 75%;
      padding-right: 20px;
      p {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
  }
}
.addressBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .addressItem {
    width: 390px;
    .addressUser {
      font-size: 14px;
    }
    .addressCompany {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      border: 1px solid #c8cdd0;
      margin: 10px 0;
      padding: 5px 10px;
      overflow-y: scroll;
    }
    .addressAddress {
      width: 100%;
      height: 96px;
      border-radius: 4px;
      border: 1px solid #c8cdd0;
      margin: 10px 0;
      padding: 5px 10px;
      overflow-y: scroll;
    }
    .addressTel {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #c8cdd0;
      margin: 10px 0;
      padding: 5px 10px;
      overflow-y: scroll;
    }
    .addressEmail {
      width: 100%;
      height: 32px;
      border-radius: 4px;
      border: 1px solid #c8cdd0;
      margin: 10px 0;
      padding: 5px 10px;
      overflow-y: scroll;
    }
  }
}
</style>