<template>
  <div>
    <el-form
      :model="form"
      status-icon
      ref="form"
      label-width="200px"
      label-position="left"
      class="demo-ruleForm"
    >
      <el-form-item label="海运出口委托书" prop="file1">
        <div class="file1">
          <div class="fileMsg">
            <p v-if="!form.file1.length">
              请上传<span>（如果不上传委托书，需要补充订舱信息）</span>
            </p>
            <div v-else>
              <p v-for="(item, index) in form.file1" :key="index">
                <span>{{ item.name }}</span>
                <span class="close" @click="handleCha(1, index)" v-if="!IsReject">x</span>
              </p>
            </div>
          </div>
          <div class="btns" v-if="!IsReject"  >
            <fileUpload
              ref="uploadComponents1"
              @selectFile="uploadFile($event, 1)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 1)"
            >
              <div class="uploadBtn">本地上传</div>
            </fileUpload>
            <div class="uploadBtn2" @click="handleDtatBank(1)">资料库选择</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="危包证"
        prop="file3"
        v-if="config.indexOf('1') > -1"
        :rules="[
          { required: true, message: '请上传危包证', trigger: 'change' },
        ]"
      >
        <div class="file1">
          <div class="fileMsg">
            <p v-if="!form.file3.length">请上传</p>
            <div v-else>
              <p v-for="(item, index) in form.file3" :key="index">
                <span>{{ item.name }}</span>
                <span class="close" @click="handleCha(3, index)" v-if="!IsReject">x</span>
              </p>
            </div>
          </div>
          <div class="btns" v-if="!IsReject">
            <fileUpload
              ref="uploadComponents3"
              @selectFile="uploadFile($event, 3)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 3)"
            >
              <div class="uploadBtn">本地上传</div>
            </fileUpload>
            <div class="uploadBtn2" @click="handleDtatBank(3)">资料库选择</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="英文MSDS"
        prop="file2"
        v-if="config.indexOf('2') > -1"
        required
        :rules="[
          { required: true, message: '请上传英文MSDS', trigger: 'change' },
        ]"
      >
        <div class="file1">
          <div class="fileMsg">
            <p v-if="!form.file2.length">请上传</p>
            <div v-else>
              <p v-for="(item, index) in form.file2" :key="index">
                <span>{{ item.name }}</span>
                <span class="close" @click="handleCha(2, index)" v-if="!IsReject">x</span>
              </p>
            </div>
          </div>
          <div class="btns" v-if="!IsReject">
            <fileUpload
              ref="uploadComponents2"
              @selectFile="uploadFile($event, 2)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 2)"
            >
              <div class="uploadBtn">本地上传</div>
            </fileUpload>
            <div class="uploadBtn2" @click="handleDtatBank(2)">资料库选择</div>
          </div>
        </div>
      </el-form-item>
      
      <el-form-item
        label="发货人电子章"
        prop="file6"
        v-if="config.indexOf('3') > -1"
        :rules="[
          { required: true, message: '请上传发货人电子章', trigger: 'change' },
        ]"
      >
        <div class="file1">
          <div class="fileMsg">
            <p v-if="!form.file6.length">请上传</p>
            <div v-else>
              <p v-for="(item, index) in form.file6" :key="index">
                <span>{{ item.name }}</span>
                <span class="close" @click="handleCha(6, index)" v-if="!IsReject">x</span>
              </p>
            </div>
          </div>
          <div class="btns" v-if="!IsReject">
            <fileUpload
              ref="uploadComponents6"
              @selectFile="uploadFile($event, 6)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 6)"
            >
              <div class="uploadBtn">本地上传</div>
            </fileUpload>
            <div class="uploadBtn2" @click="handleDtatBank(6)">资料库选择</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="非危鉴定"
        prop="file7"
        v-if="config.indexOf('4') > -1"
        :rules="[
          { required: true, message: '请上传非危鉴定', trigger: 'change' },
        ]"
      >
        <div class="file1">
          <div class="fileMsg">
            <p v-if="!form.file7.length">请上传</p>
            <div v-else>
              <p v-for="(item, index) in form.file7" :key="index">
                <span>{{ item.name }}</span>
                <span class="close" @click="handleCha(7, index)" v-if="!IsReject">x</span>
              </p>
            </div>
          </div>
          <div class="btns" v-if="!IsReject">
            <fileUpload
              ref="uploadComponents7"
              @selectFile="uploadFile($event, 7)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 7)"
            >
              <div class="uploadBtn">本地上传</div>
            </fileUpload>
            <div class="uploadBtn2" @click="handleDtatBank(7)">资料库选择</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="CAS NO." prop="casNo">
        <el-input
          v-model="form.casNo"
          :disabled="IsReject"
          size="small"
          style="width: 300px"
          placeholder="请输入CAS NO."
          @blur="searchCasNo"
        ></el-input>
        <span
          style="
            margin-left: 20px;
            font-weight: 400;
            color: #298df8;
            cursor: pointer;
          "
          class="CSNO point"
          @click="handleHelp"
          ><i class="el-icon-question" style="margin-right: 10px"></i
          >如何查看CAS NO.信息</span
        >
        <p style="color: #999">(如果货物对应多个CAS信息，请用英文，隔开)</p>
      </el-form-item>
      <el-form-item
        label="商检报告"
        prop="file4"
        :rules="[
          { required: true, message: '请上传商检报告', trigger: 'change' },
        ]"
        v-if="need"
      >
        <div class="file1">
          <div class="fileMsg">
            <p v-if="!form.file4.length">请上传</p>
            <div v-else>
              <p v-for="(item, index) in form.file4" :key="index">
                <span>{{ item.name }}</span>
                <span class="close" @click="handleCha(4, index)" v-if="!IsReject">x</span>
              </p>
            </div>
          </div>
          <div class="btns" v-if="!IsReject">
            <fileUpload
              ref="uploadComponents4"
              @selectFile="uploadFile($event, 4)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 4)"
            >
              <div class="uploadBtn">本地上传</div>
            </fileUpload>
            <div class="uploadBtn2" @click="handleDtatBank(4)">资料库选择</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.user_remarks" rows="4" type="textarea" placeholder=""></el-input>
        <div style="color: #999">如资料需延后，请在备注中说明</div>
      </el-form-item>
    </el-form>
    <el-dialog
      title="资料库"
      :visible.sync="dataBankDialog"
      width="800px"
      append-to-body
    >
      <selectDataBank
        v-if="dataBankDialog"
        :dataBankType="dataBankType"
        @uploadDataBank="uploadDataBank"
        @closeUploadDataBank="closeUploadDataBank"
      ></selectDataBank>
    </el-dialog>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import selectDataBank from "~ymp/components/bookingSpace/uploadEntrustSon/selectDataBank";

import { getFileType } from "~/baseUtils";
export default {
  props: {
    config: {
      type: String,
      default: "1,2,3,4",
    },
  },
  components: { fileUpload, selectDataBank },
  data() {
    return {
      dataBankDialog: false,
      form: {
        file1: [], //1-海运委托书
        file2: [], //2-MSDS
        file3: [], //3-危包证
        file4: [], //，4-商检报告
        file7: [], //7-非危鉴定
        file6: [], //6-发货人电子章
        casNo: "",
        user_remarks:"",
      },
      need: false,
      IsReject:false
    };
  },

  mounted() {
    if (this.$store.state.fullBookingDetails&&
      this.$store.state.fullBookingDetails.status != 0 &&
      !this.$route.query.subType
    ) {
      this.IsReject = true;
    }
    if (this.$store.state.fullBookingDetails) {
      let detail = this.$store.state.fullBookingDetails

       this.form.casNo = detail.cas_no;
       this.form.user_remarks=detail.user_remarks
       if(detail.cas_no){
         this.need = true
       }
      if (detail.attorney.length > 0) {
        let file1 = [];
        detail.attorney.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 1,
          };
          file1.push(obj);
        });
        this.form.file1 = file1;
      }
      if (detail.msds.length > 0) {
        let file2 = [];
        detail.msds.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 2,
          };
          file2.push(obj);
        });
        this.form.file2 = file2;
      }
      if (detail.dangerous_package_license.length > 0) {
        let file3 = [];
        detail.dangerous_package_license.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 3,
          };
          file3.push(obj);
        });
        this.form.file3 = file3;
      }
      if (detail.survey_report.length > 0) {
        let file4 = [];
        detail.survey_report.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 4,
          };
          file4.push(obj);
        });
        this.form.file4 = file4;
      }
      if (detail.shipper_ele_seal.length > 0) {
        let file6 = [];
        detail.shipper_ele_seal.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 6,
          };
          file6.push(obj);
        });
        this.form.file6 = file6;
      }
      if (detail.no_dangerous_identify.length > 0) {
        let file7 = [];
        detail.no_dangerous_identify.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 7,
          };
          file7.push(obj);
        });
        this.form.file7 = file7;
      }
    }
  },
  methods: {
    secondSetValues(detail){ // 控制台快速订舱用
          this.form.casNo = detail.cas_no;
          this.form.user_remarks=detail.user_remarks
       if(detail.cas_no){
         this.need = true
       }
      if (detail.attorney.length > 0) {
        let file1 = [];
        detail.attorney.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 1,
          };
          file1.push(obj);
        });
        this.form.file1 = file1;
      }
      if (detail.msds.length > 0) {
        let file2 = [];
        detail.msds.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 2,
          };
          file2.push(obj);
        });
        this.form.file2 = file2;
      }
      if (detail.dangerous_package_license.length > 0) {
        let file3 = [];
        detail.dangerous_package_license.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 3,
          };
          file3.push(obj);
        });
        this.form.file3 = file3;
      }
      if (detail.survey_report.length > 0) {
        let file4 = [];
        detail.survey_report.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 4,
          };
          file4.push(obj);
        });
        this.form.file4 = file4;
      }
      if (detail.shipper_ele_seal.length > 0) {
        let file6 = [];
        detail.shipper_ele_seal.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 6,
          };
          file6.push(obj);
        });
        this.form.file6 = file6;
      }
      if (detail.no_dangerous_identify.length > 0) {
        let file7 = [];
        detail.no_dangerous_identify.forEach((item) => {
          let obj = {
            attachment_id: item.id,
            name: item.name,
            format: item.format,
            size: item.size,
            url: item.url,
            file_type: 7,
          };
          file7.push(obj);
        });
        this.form.file7 = file7;
      }
    },
    getSecondStepData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
           this.$emit("noSecond",false);

          this.$emit("SecondtData", this.form);
        } else {
           this.$emit("noSecond",true);
          console.log("error submit!!");
          return false;
        }
      });
    },
    async searchCasNo(e) {
      let str = this.form.casNo;
      let data = await this.$store.dispatch(
        "API_fcl_bookingSpace/searchCasNo",
        {
          cas_no: str,
          is_background: 0,
          nopage: 1,
        }
      );
      this.need = false;
      if (data.data.length) {
        this.need = true;
      }
    },
    handleHelp() {
      window.open(
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/casno.jpg"
      );
    },
    uploadFile(val, index) {
      let obj = {
        name: val[0].name,
        file_type: index,
        size: (val[0].size / 1024).toFixed(2),
        format: getFileType(val[0].name),
      };
      if (this.form[`file${index}`].length < 5) {
        let file = Object.assign({}, obj);
        this.form[`file${index}`].push(file);
        this.$refs[`uploadComponents${index}`].uploadFileToOss();
      } else {
        this.$message.warning("不能超过5个");
      }
    },
    uploadCompleteOSS(val, index) {
      this.$refs['form'].clearValidate([`file${index}`]);
      this.form[`file${index}`][this.form[`file${index}`].length - 1].url =
        val.url;
    },
    handleCha(index, num) {
      this.form[`file${index}`].splice(num, 1);
    },
    handleDtatBank(val) {
      this.dataBankType = val;
      this.dataBankDialog = true;
    },
    uploadDataBank(row, type) {
      console.log(row, type);
       this.$refs['form'].clearValidate([`file${type}`]);
      if (this.form[`file${type}`].length < 5) {
        let num = this.form[`file${type}`].findIndex(
          (item) => item.attachment_id == row.attachment_id
        );
        if (num == -1) {
          this.form[`file${type}`].push(Object.assign({}, row));
          this.dataBankDialog = false;
        } else {
          this.$message.warning("不能重复选择资料");
        }
      } else {
        this.$message.warning("不能超过5个");
      }
    },
    //  关闭资料库
    closeUploadDataBank() {
      this.dataBankDialog = false;
    },
  },
};
</script>

<style scoped lang="less">
.uploadBtn {
  color: #415058;
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  line-height: 32px;
}

.uploadBtn2 {
  color: #415058;
  width: 94px;
  height: 32px;
  background: #f0f2f5;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  text-align: center;
  margin-left: 12px;
  cursor: pointer;
}
.file1 {
  display: flex;
  justify-content: space-between;
  .btns {
    display: flex;
    align-items: center;
  }
  .fileMsg {
    span {
      color: #999;
    }
    .close {
      padding-left: 15px;
      cursor: pointer;
      font-weight: 900;
      color: #000;
    }
  }
}
</style>