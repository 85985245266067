var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{ref:"form",staticClass:"demo-ruleForm",attrs:{"model":_vm.form,"status-icon":"","label-width":"200px","label-position":"left"}},[_c('el-form-item',{attrs:{"label":"海运出口委托书","prop":"file1"}},[_c('div',{staticClass:"file1"},[_c('div',{staticClass:"fileMsg"},[(!_vm.form.file1.length)?_c('p',[_vm._v(" 请上传"),_c('span',[_vm._v("（如果不上传委托书，需要补充订舱信息）")])]):_c('div',_vm._l((_vm.form.file1),function(item,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.IsReject)?_c('span',{staticClass:"close",on:{"click":function($event){return _vm.handleCha(1, index)}}},[_vm._v("x")]):_vm._e()])}),0)]),(!_vm.IsReject)?_c('div',{staticClass:"btns"},[_c('fileUpload',{ref:"uploadComponents1",on:{"selectFile":function($event){return _vm.uploadFile($event, 1)},"uploadCompleteOSS":function($event){return _vm.uploadCompleteOSS($event, 1)}}},[_c('div',{staticClass:"uploadBtn"},[_vm._v("本地上传")])]),_c('div',{staticClass:"uploadBtn2",on:{"click":function($event){return _vm.handleDtatBank(1)}}},[_vm._v("资料库选择")])],1):_vm._e()])]),(_vm.config.indexOf('1') > -1)?_c('el-form-item',{attrs:{"label":"危包证","prop":"file3","rules":[
        { required: true, message: '请上传危包证', trigger: 'change' },
      ]}},[_c('div',{staticClass:"file1"},[_c('div',{staticClass:"fileMsg"},[(!_vm.form.file3.length)?_c('p',[_vm._v("请上传")]):_c('div',_vm._l((_vm.form.file3),function(item,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.IsReject)?_c('span',{staticClass:"close",on:{"click":function($event){return _vm.handleCha(3, index)}}},[_vm._v("x")]):_vm._e()])}),0)]),(!_vm.IsReject)?_c('div',{staticClass:"btns"},[_c('fileUpload',{ref:"uploadComponents3",on:{"selectFile":function($event){return _vm.uploadFile($event, 3)},"uploadCompleteOSS":function($event){return _vm.uploadCompleteOSS($event, 3)}}},[_c('div',{staticClass:"uploadBtn"},[_vm._v("本地上传")])]),_c('div',{staticClass:"uploadBtn2",on:{"click":function($event){return _vm.handleDtatBank(3)}}},[_vm._v("资料库选择")])],1):_vm._e()])]):_vm._e(),(_vm.config.indexOf('2') > -1)?_c('el-form-item',{attrs:{"label":"英文MSDS","prop":"file2","required":"","rules":[
        { required: true, message: '请上传英文MSDS', trigger: 'change' },
      ]}},[_c('div',{staticClass:"file1"},[_c('div',{staticClass:"fileMsg"},[(!_vm.form.file2.length)?_c('p',[_vm._v("请上传")]):_c('div',_vm._l((_vm.form.file2),function(item,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.IsReject)?_c('span',{staticClass:"close",on:{"click":function($event){return _vm.handleCha(2, index)}}},[_vm._v("x")]):_vm._e()])}),0)]),(!_vm.IsReject)?_c('div',{staticClass:"btns"},[_c('fileUpload',{ref:"uploadComponents2",on:{"selectFile":function($event){return _vm.uploadFile($event, 2)},"uploadCompleteOSS":function($event){return _vm.uploadCompleteOSS($event, 2)}}},[_c('div',{staticClass:"uploadBtn"},[_vm._v("本地上传")])]),_c('div',{staticClass:"uploadBtn2",on:{"click":function($event){return _vm.handleDtatBank(2)}}},[_vm._v("资料库选择")])],1):_vm._e()])]):_vm._e(),(_vm.config.indexOf('3') > -1)?_c('el-form-item',{attrs:{"label":"发货人电子章","prop":"file6","rules":[
        { required: true, message: '请上传发货人电子章', trigger: 'change' },
      ]}},[_c('div',{staticClass:"file1"},[_c('div',{staticClass:"fileMsg"},[(!_vm.form.file6.length)?_c('p',[_vm._v("请上传")]):_c('div',_vm._l((_vm.form.file6),function(item,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.IsReject)?_c('span',{staticClass:"close",on:{"click":function($event){return _vm.handleCha(6, index)}}},[_vm._v("x")]):_vm._e()])}),0)]),(!_vm.IsReject)?_c('div',{staticClass:"btns"},[_c('fileUpload',{ref:"uploadComponents6",on:{"selectFile":function($event){return _vm.uploadFile($event, 6)},"uploadCompleteOSS":function($event){return _vm.uploadCompleteOSS($event, 6)}}},[_c('div',{staticClass:"uploadBtn"},[_vm._v("本地上传")])]),_c('div',{staticClass:"uploadBtn2",on:{"click":function($event){return _vm.handleDtatBank(6)}}},[_vm._v("资料库选择")])],1):_vm._e()])]):_vm._e(),(_vm.config.indexOf('4') > -1)?_c('el-form-item',{attrs:{"label":"非危鉴定","prop":"file7","rules":[
        { required: true, message: '请上传非危鉴定', trigger: 'change' },
      ]}},[_c('div',{staticClass:"file1"},[_c('div',{staticClass:"fileMsg"},[(!_vm.form.file7.length)?_c('p',[_vm._v("请上传")]):_c('div',_vm._l((_vm.form.file7),function(item,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.IsReject)?_c('span',{staticClass:"close",on:{"click":function($event){return _vm.handleCha(7, index)}}},[_vm._v("x")]):_vm._e()])}),0)]),(!_vm.IsReject)?_c('div',{staticClass:"btns"},[_c('fileUpload',{ref:"uploadComponents7",on:{"selectFile":function($event){return _vm.uploadFile($event, 7)},"uploadCompleteOSS":function($event){return _vm.uploadCompleteOSS($event, 7)}}},[_c('div',{staticClass:"uploadBtn"},[_vm._v("本地上传")])]),_c('div',{staticClass:"uploadBtn2",on:{"click":function($event){return _vm.handleDtatBank(7)}}},[_vm._v("资料库选择")])],1):_vm._e()])]):_vm._e(),_c('el-form-item',{attrs:{"label":"CAS NO.","prop":"casNo"}},[_c('el-input',{staticStyle:{"width":"300px"},attrs:{"disabled":_vm.IsReject,"size":"small","placeholder":"请输入CAS NO."},on:{"blur":_vm.searchCasNo},model:{value:(_vm.form.casNo),callback:function ($$v) {_vm.$set(_vm.form, "casNo", $$v)},expression:"form.casNo"}}),_c('span',{staticClass:"CSNO point",staticStyle:{"margin-left":"20px","font-weight":"400","color":"#298df8","cursor":"pointer"},on:{"click":_vm.handleHelp}},[_c('i',{staticClass:"el-icon-question",staticStyle:{"margin-right":"10px"}}),_vm._v("如何查看CAS NO.信息")]),_c('p',{staticStyle:{"color":"#999"}},[_vm._v("(如果货物对应多个CAS信息，请用英文，隔开)")])],1),(_vm.need)?_c('el-form-item',{attrs:{"label":"商检报告","prop":"file4","rules":[
        { required: true, message: '请上传商检报告', trigger: 'change' },
      ]}},[_c('div',{staticClass:"file1"},[_c('div',{staticClass:"fileMsg"},[(!_vm.form.file4.length)?_c('p',[_vm._v("请上传")]):_c('div',_vm._l((_vm.form.file4),function(item,index){return _c('p',{key:index},[_c('span',[_vm._v(_vm._s(item.name))]),(!_vm.IsReject)?_c('span',{staticClass:"close",on:{"click":function($event){return _vm.handleCha(4, index)}}},[_vm._v("x")]):_vm._e()])}),0)]),(!_vm.IsReject)?_c('div',{staticClass:"btns"},[_c('fileUpload',{ref:"uploadComponents4",on:{"selectFile":function($event){return _vm.uploadFile($event, 4)},"uploadCompleteOSS":function($event){return _vm.uploadCompleteOSS($event, 4)}}},[_c('div',{staticClass:"uploadBtn"},[_vm._v("本地上传")])]),_c('div',{staticClass:"uploadBtn2",on:{"click":function($event){return _vm.handleDtatBank(4)}}},[_vm._v("资料库选择")])],1):_vm._e()])]):_vm._e(),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{attrs:{"rows":"4","type":"textarea","placeholder":""},model:{value:(_vm.form.user_remarks),callback:function ($$v) {_vm.$set(_vm.form, "user_remarks", $$v)},expression:"form.user_remarks"}}),_c('div',{staticStyle:{"color":"#999"}},[_vm._v("如资料需延后，请在备注中说明")])],1)],1),_c('el-dialog',{attrs:{"title":"资料库","visible":_vm.dataBankDialog,"width":"800px","append-to-body":""},on:{"update:visible":function($event){_vm.dataBankDialog=$event}}},[(_vm.dataBankDialog)?_c('selectDataBank',{attrs:{"dataBankType":_vm.dataBankType},on:{"uploadDataBank":_vm.uploadDataBank,"closeUploadDataBank":_vm.closeUploadDataBank}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }